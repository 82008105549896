export default function (api) {
    return {
        login(data, headers) {
            return api.post('/login', data, {headers, shouldAuth: false})
        },
        getCategoryList(data) {
            return api.get('/category', {params: data, shouldAuth: true})
        },
        createGoods(data) {
            return api.post('/goods', data, {shouldAuth: true})
        },
        updateGoods(id, data) {
            return api.put('/goods/' + id, data, {shouldAuth: true})
        },
        deleteGoods(id) {
            return api.delete('/goods/' + id, {shouldAuth: true})
        },
        getGoodsList(data) {
            return api.get('/goods', {params: data, shouldAuth: true})
        },
        getGoodsDetail(id) {
            return api.get('/goods/' + id, {shouldAuth: true})
        },
        createGoodsImage(id, data) {
            return api.post(`/goods/${id}/image`, data, {shouldAuth: true})
        },
        updateGoodsImage(id, image_id, data) {
            return api.put(`/goods/${id}/image/${image_id}`, data, {shouldAuth: true})
        },
        deleteGoodsImage(id, image_id) {
            return api.delete(`/goods/${id}/image/${image_id}`, {shouldAuth: true})
        },
        getGoodsImageList(id) {
            return api.get('/goods/' + id + '/image', {shouldAuth: true})
        },
        // search
        getSearchHistoryList(data) {
            return api.get('/search/history', {params: data, shouldAuth: true})
        },
        // gallery
        getGalleryList(data) {
            return api.get('/gallery', {params: data, shouldAuth: true})
        },
        createGallery(data) {
            return api.post('/gallery', data, {shouldAuth: true})
        },
        chooseGallery(id) {
            return api.post('/gallery/' + id + '/choose', {}, {shouldAuth: true})
        },
        getDefaultGallery() {
            return api.get('/gallery/default', {shouldAuth: true})
        },
        editGallery(id, data) {
            return api.put('/gallery/' + id, data, {shouldAuth: true})
        }
    }
}
