import { createAPI } from './create-api'
import auth from './module/auth'
import { ElMessage } from 'element-plus';
import router from "@/router";

const api = createAPI({ baseURL: '/api/admin' })
//请求拦截器
api.interceptors.request.use(
  (config) => {
    // 获取Authorization
    const authorization = sessionStorage.getItem('authorization');
    // 设置到headers中
    if (authorization) {
      config.headers.Authorization = `Basic ${ authorization }`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//响应拦截器
let goLoginFlagTimer = null;
api.interceptors.response.use(
  (response) => {
    // console.log(router.currentRoute.value.name)
    const config = response.config;
    return response;
  },
  async (error) => {
    const status = error?.response?.status;

    switch (status) {
      case 400:
      case 403:
        const errorList = error?.response?.data;
        if (typeof errorList === "object") {
          ElMessage.error({
            message: errorList.message,
            'custom-class': "common-message-error"
          });
        }
        break;
      case 401:
        //   message.error(error.response.data);
        // case 403:
        const routeName = router.currentRoute.value.name;
        if (routeName !== "Login") {
          clearTimeout(goLoginFlagTimer);
          goLoginFlagTimer = setTimeout(() => {
            ElMessage.error({
              message: '请重新登录',
              'custom-class': "common-message-error"
            });
            router.replace({ name: "Login" });
          }, 200);
        } else {
          const errorList = error?.response?.data;
          if (typeof errorList === "object") {
            ElMessage.error({
              message: errorList.message,
              'custom-class': "common-message-error"
            });
          }
        }
        break;
      default:
        ElMessage.error({
          message: "发生错误。code:" + status,
          'custom-class': "common-message-error"
        });
        break;
    }
    return Promise.reject(error);
  }
);

export const authApi = auth(api)
