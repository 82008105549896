import {createStore} from 'vuex'
import {authApi} from "@/api";

export default createStore({
  state: {
    gallery: {},
  },
  getters: {
    gallery: (state) => state.gallery,
  },
  mutations: {
    setGallery(state, data) {
      state.gallery = data;
    },
  },
  actions: {
    setGallery({commit}, data) {
      authApi.getDefaultGallery().then(res => {
        commit("setGallery", res.data);
      })
    },
  },
  modules: {}
})
