import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: {name: 'Login'},
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () =>
            import(/* webpackChunkName: "Admin" */ '../views/admin/Index.vue'),
        children: [
            {
                path: '/admin',
                redirect: {name: 'GoodsList'},
            },
            {
                path: '/gallery/list',
                name: 'GalleryList',
                meta: {
                    shouldLogin: true,
                    isList: true
                },
                component: () => import(/* webpackChunkName: "GalleryList" */ '../views/admin/gallery/GalleryList.vue')
            },
            {
                path: '/goods/list',
                name: 'GoodsList',
                meta: {
                    shouldLogin: true,
                    isList: true
                },
                component: () => import(/* webpackChunkName: "GoodsList" */ '../views/admin/goods/GoodsList.vue')
            },
            {
                path: '/search/history',
                name: 'HistoryList',
                meta: {
                    shouldLogin: true,
                    isList: true
                },
                component: () => import(/* webpackChunkName: "HistoryList" */ '../views/admin/search/HistoryList.vue')
            }
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
